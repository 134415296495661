import React from 'react'

function Mobilenav() {
  return (
    <div className="mobile-nav__wrapper">
  <div className="mobile-nav__overlay mobile-nav__toggler" />
  {/* /.mobile-nav__overlay */}
  <div className="mobile-nav__content">
    <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times" /></span>
    <div className="logo-box">
      <a href="/" aria-label="logo image"><img src="assets/images/logo-lightt.png" width={36} alt="ogency" /></a>
    </div>
    {/* /.logo-box */}
    <div className="mobile-nav__container" />
    {/* /.mobile-nav__container */}
    <ul className="mobile-nav__contact list-unstyled">
      <li>
        <i className="fas fa-envelope" />
        <a href="codeloop.tech@gmail.com">codeloop.tech@gmail.com</a>
      </li>
      {/* <li>
        <i className="icon-phone-call" />
        <a href="tel:+923366031601">+923366031601</a>
      </li> */}
    </ul>{/* /.mobile-nav__contact */}
    <div className="mobile-nav__social">
    <a href="mailto:codeloop.tech@gmail.com?subject=Free Consultation&body=Hello,%0D%0A%0D%0AI am interested in a free consultation. Please let me know your availability.%0D%0A%0D%0AThank you!">
            <i className="fab fa-google" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61558585827626">
            <i className="fab fa-facebook" />
          </a>
          <a href="https://www.instagram.com/codeloop.tech/">
            <i className="fab fa-instagram" />
          </a>
    </div>{/* /.mobile-nav__social */}
  </div>
  {/* /.mobile-nav__content */}
</div>
  )
}

export default Mobilenav
