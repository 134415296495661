import React from 'react'

function Slidingtextt() {
  return (
    <div>
         {/*Sliding Text Start */}
    <section className="sliding-text @@extraClassName">
      <div className="sliding-text__wrap">
        <ul className="sliding-text__list list-unstyled">
          <li>*Transofrm ideas into reality</li>
          <li>*INSPIRED WITH CREATIVITY</li>
          <li>*Design &amp; development craft</li>
          <li>*unlock the potential</li>
          <li>*Transofrm ideas into reality</li>
          <li>*INSPIRED WITH CREATIVITY</li>
          <li>*Design &amp; development craft</li>
          <li>*unlock the potential</li>
          <li>*Transofrm ideas into reality</li>
          <li>*INSPIRED WITH CREATIVITY</li>
          <li>*Design &amp; development craft</li>
          <li>*unlock the potential</li>
          <li>*Transofrm ideas into reality</li>
        </ul>
      </div>
    </section>
    </div>
  )
}

export default Slidingtextt
