import React from "react";

function Footer() {
  return (
    <div>
      <footer
        className="main-footer"
        style={{
          backgroundImage: "url(assets/images/backgrounds/footer-bg-1.png)",
        }}
      >
        <div className="container">
          <div
            className="main-footer__top wow fadeInUp animated"
            data-wow-delay="100ms"
          >
            <a href="/" className="main-footer__logo">
              <img
                src="./assets/images/codeloop/footerlogo.png"
                alt="ogency"
                width={130}
                height={130}
              />
            </a>
            {/* /.footer-logo */}
            <div className="main-footer__social">
              <a href="mailto:codeloop.tech@gmail.com?subject=Free Consultation&body=Hello,%0D%0A%0D%0AI am interested in a free consultation. Please let me know your availability.%0D%0A%0D%0AThank you!">
                <i className="fab fa-google" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61558585827626">
                <i className="fab fa-facebook" />
              </a>
              {/* <a href="https://wa.me/+923366031601?text=Hello%20there!">
                <i className="fab fa-whatsapp" />
              </a> */}
              <a href="https://www.instagram.com/codeloop.tech/">
                <i className="fab fa-instagram" />
              </a>
            </div>
            {/* /.footer-social */}
          </div>
          {/* footer-top */}
          <div className="row">
            <div
              className="col-lg-8 col-md-6 wow fadeInUp animated"
              data-wow-delay="200ms"
            >
              <div className="main-footer__about">
                <p className="footer-widget__text">
                  Let’s start working together
                </p>

                <span className="display-2 fw-lighter">
                  <a
                    href="mailto:codeloop.tech@gmail.com?subject=Free Consultation&body=Hello,%0D%0A%0D%0AI am interested in a free consultation. Please let me know your availability.%0D%0A%0D%0AThank you!"
                    style={{
                      textDecoration: "none",
                      fontSize: "40px",
                      fontWeight: "lighter",
                    }}
                  >
                    codeloop.tech@gmail.com
                  </a>
                </span>
              </div>
              {/* /.footer-widget */}
            </div>
            <div
              className="col-lg-2 col-md-3 wow fadeInUp animated"
              data-wow-delay="300ms"
            >
              <div className="main-footer__navmenu">
                <ul>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/webdevelopment">Web development</a>
                  </li>
                  <li>
                    <a href="/appdevelopment">App development</a>
                  </li>
                  <li>
                    <a href="/digitalmarketing">Digital Marketing</a>
                  </li>
                  <li>
                    <a href="/seo">SEO</a>
                  </li>
                </ul>
                {/* /.list-unstyled */}
              </div>
              {/* /.footer-widget */}
            </div>
            <div
              className="col-lg-2 col-md-3 wow fadeInUp animated"
              data-wow-delay="400ms"
            >
              <div className="main-footer__navmenu">
                <ul>
                  <li>
                    <a href="/contactus">Contact Us</a>
                  </li>
                  <li>
                    <a href="/aboutus">About Us</a>
                  </li>
                  <li>
                    <a
                      href="./assets/images/codeloop/portfolio.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Porfolio
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://wa.me/+923366031601?text=Hello%20there!">
                      Help
                    </a>
                  </li> */}
                </ul>
                {/* /.list-unstyled */}
              </div>
              {/* /.footer-widget */}
            </div>
          </div>
          {/* /.row */}
          <p
            className="main-footer__copyright wow fadeInUp animated"
            data-wow-delay="500ms"
          >
            © Copyright <span className="dynamic-year" />
            {/* /.dynamic-year */} by{" "}
            <a href="index.html">Code Loop Technology</a>
          </p>
        </div>
        {/* /.container */}
      </footer>
      {/* /.main-footer */}
    </div>
  );
}

export default Footer;
