import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div>
      <header className="main-header">
        <nav className="main-menu">
          <div className="container-fluid">
            <div className="main-menu__logo">
              <a href="/">
                <img
                  src="./assets/images/logo-lightt.png"
                  width={50}
                  height={50}
                  alt="logo"
                />
              </a>
            </div>
            {/* /.main-menu__logo */}
            <div className="main-menu__nav">
              <ul className="main-menu__list">
                <li>
                  <a href="/">Home</a> 
                </li>

                <li className="dropdown">
                  <Link to="/services">Services</Link>
                  <ul>
                    <li>
                      <Link to="/webdevelopment">Website development</Link>
                    </li>
                    <li>
                      <Link to="/graphicdesigning">Graphic designing</Link>
                    </li>
                    <li>
                      <Link to="/digitalmarketing">Digital marketing</Link>
                    </li>
                    <li>
                      <Link to="/appdevelopment">App development</Link>
                    </li>
                    <li>
                      <Link to="/leadgeneration">Lead Generation</Link>
                    </li>
                    <li>
                      <Link to="/seo">SEO</Link>
                    </li>
                    <li>
                      <Link to="/emailmarketing">Email Marketing</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="./assets/images/codeloop/portfolio.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Portfolio
                  </a>
                </li>
                {/* <li>
              <a to="/pricing">Pricing</a>
            </li> */}
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
              </ul>
            </div>
            {/* /.main-menu__nav */}
            <div className="main-menu__right">
              <a href="/" className="main-menu__toggler mobile-nav__toggler">
                <i className="fa fa-bars" />
              </a>
              {/* /.mobile menu btn */}
              <div className="ms-3">
                <div className="ms-3">
                  <a
                    href="mailto:codeloop.tech@gmail.com?subject=Free Consultation&body=Hello,%0D%0A%0D%0AI am interested in a free consultation. Please let me know your availability.%0D%0A%0D%0AThank you!"
                    className="ogency-btnn"
                  >
                    Free Consultation
                  </a>
                </div>
              </div>
            </div>
            {/* /.main-menu__right */}
          </div>
          {/* /.container */}
        </nav>
        {/* /.main-menu */}
      </header>
      {/* /.main-header */}
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content" />
        {/* /.sticky-header__content */}
      </div>
      {/* /.stricky-header */}
    </div>
  );
}

export default Header;
