import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Mobilenav from "../components/common/Mobilenav";

function AppDevelopment() {
  return (
    <div class="page-wrapper">
      <Header />
      <div>
        {/* /.stricky-header */}
        <section className="page-header">
          <div className="page-header__bg" />
          {/* /.page-header__bg */}
          <div className="page-header__overlay" />
          {/* /.page-header__bg */}
          <div className="container">
            <ul className="page-header__breadcrumb list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/services">Services</a>
              </li>
              <li>
                <span>Service Details</span>
              </li>
            </ul>
            {/* /.page-breadcrumb list-unstyled */}
            <h2 className="page-header__title">App Development</h2>
            {/* /.page-title */}
          </div>
          {/* /.container */}
        </section>
        {/* /.page-header */}
        {/* Services Details Start */}
        <section className="services-details">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-4 col-lg-5 wow fadeInUp animated"
                data-wow-delay="300ms"
              >
                <div className="services-details__sidebar">
                  <div className="services-details__services-list">
                    <ul className="services-details__services list-unstyled">
                      <li>
                        <a href="/webdevelopment">Website development</a>
                      </li>
                      <li>
                        <a href="/appdevelopment">App development</a>
                      </li>
                      <li>
                        <a href="/graphicdesigning">Graphic designing</a>
                      </li>
                      <li>
                        <a href="/digitalmarketing">Digital marketing</a>
                      </li>
                      <li>
                        <a href="/seo">SEO</a>
                      </li>
                      <li>
                        <a href="/leadgeneration">Lead Generation</a>
                      </li>
                      <li>
                        <a href="/emailmarketing">Email Marketing</a>
                      </li>
                    </ul>
                  </div>
                  {/* /.service-widget */}
                  <div className="services-details__banner">
                    <div className="services-details__banner__shape" />
                    <div className="services-details__banner__image">
                      {/* <img
                        src="assets/images/resources/banner-author.png"
                        alt="ogency"
                      /> */}
                    </div>
                    <div className="services-details__banner__icon">
                      <span className="icon-mobile-development" />
                    </div>
                    <h3 className="services-details__banner__title">
                      Are you looking for
                      <br /> a application development
                    </h3>
                    <a className="ogency-btn"  href="mailto:codeloop.tech@gmail.com?subject=Free Consultation&body=Hello,%0D%0A%0D%0AI am interested in a free consultation. Please let me know your availability.%0D%0A%0D%0AThank you!"
>
                      Contact Us
                    </a>
                  </div>
                  {/* /.service-widget */}
                  <div className="services-details__document">
                    <div className="services-details__document__icon">
                      <span className="icon-pdf-file" />
                    </div>
                    <h3 className="services-details__document__title">
                      <a href="contact.html">Project agreement form</a>
                    </h3>
                    <p className="services-details__document__text">3.9KB</p>
                  </div>
                  {/* /.service-widget */}
                </div>
                {/* /.service-sidebar */}
              </div>
              {/* /.column */}
              <div
                className="col-xl-8 col-lg-7 wow fadeInUp animated"
                data-wow-delay="400ms"
              >
                <div className="services-details__content">
                  <div className="services-details__thumb">
                    <img
                      src="assets/images/codeloop/services/app.png"
                      alt="ogency"
                    />
                  </div>
                  <h3 className="services-details__content__title">
                    App Development
                  </h3>
                  <p className="services-details__content__text">
                    "Empower your business with custom app development at Code
                    Loop Technology. We create intuitive, scalable, and
                    innovative mobile solutions tailored to your needs. From
                    concept to launch, trust us to turn your ideas into
                    functional and impactful apps."
                  </p>
                  <h4 className="services-details__content__title-two">
                    Helping clients to solve appilcation development problems
                  </h4>
                  <p className="services-details__content__text">
                    Our expert team addresses your app development challenges
                    with customized solutions, ensuring your application is
                    user-friendly, efficient, and meets your business
                    objectives. We deliver high-quality, timely results and
                    provide ongoing support to ensure optimal performance and
                    continuous improvement.
                  </p>
                  <h3 className="services-details__content__title-three">
                    Why choose?
                  </h3>
                  <p className="services-details__content__text">
                    Our skilled app developers create tailored, cutting-edge
                    solutions that perfectly match your business needs, ensuring
                    satisfaction with high-quality, on-time work and continuous
                    support to keep your app up-to-date and performing at its
                    best.
                  </p>
                  <ul className="services-details__points list-unstyled">
                    <li>
                      <div className="services-details__points__icon">
                        <span className="icon-success" />
                      </div>
                      <h3 className="services-details__points__title">
                        Best creative
                        <br /> agency
                      </h3>
                    </li>
                    <li>
                      <div className="services-details__points__icon">
                        <span className="icon-job-promotion" />
                      </div>
                      <h3 className="services-details__points__title">
                        99% success
                        <br /> rate
                      </h3>
                    </li>
                    <li>
                      <div className="services-details__points__icon">
                        <span className="icon-marketing" />
                      </div>
                      <h3 className="services-details__points__title">
                        Quality
                        <br /> marketing
                      </h3>
                    </li>
                  </ul>
                  <div className="services-details__benefit">
                    <div className="row">
                      <div className="col-xl-6 col-lg-12 col-md-6">
                        <div className="services-details__benefit__img">
                        <img
                            src="./assets/images/codeloop/services/logodark.png"
                            alt="ogency"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-12 col-md-6">
                        <div className="services-details__benefit__content">
                          <h3 className="services-details__benefit__title">
                            Our benefits
                          </h3>
                          <p className="services-details__benefit__text">
                          Get the best app development from the Code Loop Technology
                          </p>
                          <ul className="services-details__benefit__lists list-unstyled">
                            <li>
                              <span className="fa fa-check-circle" />
                              Cost Effective
                            </li>
                            <li>
                              <span className="fa fa-check-circle" />
                              Equal Opportunities
                            </li>
                            <li>
                              <span className="fa fa-check-circle" />
                              Creates High Brand Awareness
                            </li>
                            <li>
                              <span className="fa fa-check-circle" />
                              Higher ROI
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Services Details End */}
      </div>

      <Footer />
      <Mobilenav />
    </div>
  );
}

export default AppDevelopment;
