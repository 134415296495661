import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Mobilenav from "../components/common/Mobilenav";
import Testimonials from "../components/Testimonials";
import Slidingtextt from "../components/Slidingtextt";

function Aboutus() {
  return (
    <div class="page-wrapper">
      <Header />
      <div>
        <section className="page-header">
          <div className="page-header__bg" />
          {/* /.page-header__bg */}
          <div className="page-header__overlay" />
          {/* /.page-header__bg */}
          <div className="container">
            <ul className="page-header__breadcrumb list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <span>About</span>
              </li>
            </ul>
            {/* /.page-breadcrumb list-unstyled */}
            <h2 className="page-header__title">About Us</h2>
            {/* /.page-title */}
          </div>
          {/* /.container */}
        </section>
        {/* /.page-header */}
        {/* About Start */}
        <section className="about-three">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-three__thumb">
                  {/* about thumb start */}
                  <div
                    className="about-three__thumb--one wow fadeInLeft animated"
                    data-wow-delay="300ms"
                  >
                    <img
                      src="assets/images/codeloop/aboutone.png"
                      alt="ogency"
                      height={316}
                      width={290}
                    />
                  </div>
                  <div
                    className="about-three__thumb--two wow fadeInLeft animated"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/codeloop/abouttwo.png"
                      alt="ogency"
                      height={496}
                      width={400}
                    />
                  </div>
                </div>
                {/* about thumb end */}
              </div>
              <div className="col-lg-6">
                <div className="about-three__content">
                  {/* about content start*/}
                  <div className="section-title">
                    <h5 className="section-title__tagline section-title__tagline--has-dots">
                      about Company
                    </h5>
                    <h2 className="section-title__title">
                      Get to know about{" "}
                      <span style={{ color: "#f40404" }}>
                        Code<span>Loop </span>
                      </span>
                      Technology
                    </h2>
                  </div>
                  {/* section-title */}
                  <h4 className="about-three__content__heading">
                    Innovation at the Heart of Technology
                  </h4>
                  <p className="about-three__content__text">
                    At Code Loop Technology, we drive digital transformation
                    with innovative, custom solutions. Our expertise includes
                    web and app development, SEO, digital marketing, and lead
                    generation. We create user-friendly websites and mobile
                    apps, enhance online visibility with effective SEO
                    strategies, and maximize engagement through tailored digital
                    marketing. Our targeted lead generation connects businesses
                    with potential customers, fostering growth and boosting
                    conversions. Committed to excellence, we deliver results
                    that propel your business forward in the digital landscape.
                  </p>
                  <div className="">
                    <a
                      href="mailto:codeloop.tech@gmail.com?subject=Free Consultation&body=Hello,%0D%0A%0D%0AI am interested in a free consultation. Please let me know your availability.%0D%0A%0D%0AThank you!"
                      className="ogency-btn"
                    >
                      Send Message
                    </a>
                  </div>
                </div>
                {/* about content end*/}
              </div>
            </div>
          </div>
        </section>
        {/* About End */}
        {/* Sliding Text Start*/}
        <section className="slider-text-one">
          <div className="slider-text-one__animate-text">
            <span>
              Our satisfied <span>&amp;</span> happy clients&nbsp;
            </span>
            <span>
              Our satisfied <span>&amp;</span> happy clients&nbsp;
            </span>
          </div>
        </section>
        {/* Sliding Text Start*/}
        {/*Testimonial Start*/}
        <Slidingtextt />
        <Testimonials />
        {/*Testimonial End*/}
        {/* Team Start */}
        {/* Team Start */}

        {/* /.client-carousel */}
      </div>

      <Footer />
      <Mobilenav />
    </div>
  );
}

export default Aboutus;
