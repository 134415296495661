import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Mobilenav from "../components/common/Mobilenav";

function DigitalMarketing() {
  return (
    <div class="page-wrapper">
      <Header />
      <div>
        {/* /.stricky-header */}
        <section className="page-header">
          <div className="page-header__bg" />
          {/* /.page-header__bg */}
          <div className="page-header__overlay" />
          {/* /.page-header__bg */}
          <div className="container">
            <ul className="page-header__breadcrumb list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
              <a href="/services">Services</a>
              </li>
              <li>
                <span>Service Details</span>
              </li>
            </ul>
            {/* /.page-breadcrumb list-unstyled */}
            <h2 className="page-header__title">Digital Marketing</h2>
            {/* /.page-title */}
          </div>
          {/* /.container */}
        </section>
        {/* /.page-header */}
        {/* Services Details Start */}
        <section className="services-details">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-4 col-lg-5 wow fadeInUp animated"
                data-wow-delay="300ms"
              >
                <div className="services-details__sidebar">
                  <div className="services-details__services-list">
                    <ul className="services-details__services list-unstyled">
                      <li>
                        <a href="/webdevelopment">Website development</a>
                      </li>
                      <li>
                        <a href="/appdevelopment">App development</a>
                      </li>
                      <li>
                        <a href="/graphicdesigning">Graphic designing</a>
                      </li>
                      <li>
                        <a href="/digitalmarketing">Digital marketing</a>
                      </li>
                      <li>
                        <a href="/seo">SEO</a>
                      </li>
                      <li>
                        <a href="/leadgeneration">Lead Generation</a>
                      </li>
                      <li>
                        <a href="/emailmarketing">Email Marketing</a>
                      </li>
                    </ul>
                  </div>
                  {/* /.service-widget */}
                  <div className="services-details__banner">
                    <div className="services-details__banner__shape" />
                    <div className="services-details__banner__image">
                      {/* <img
                        src="assets/images/codeloop/services/sm1.png"
                        alt="ogency"
                      /> */}
                    </div>
                    <div className="services-details__banner__icon">
                      <span className="icon-mobile-development" />
                    </div>
                    <h3 className="services-details__banner__title">
                      Are you looking for
                      <br /> a digital marketing
                    </h3>
                    <a className="ogency-btn"  href="mailto:codeloop.tech@gmail.com?subject=Free Consultation&body=Hello,%0D%0A%0D%0AI am interested in a free consultation. Please let me know your availability.%0D%0A%0D%0AThank you!"
>
                      Contact Us
                    </a>
                  </div>
                  {/* /.service-widget */}
                  <div className="services-details__document">
                    <div className="services-details__document__icon">
                      <span className="icon-pdf-file" />
                    </div>
                    <h3 className="services-details__document__title">
                      <a href="contact.html">Project agreement form</a>
                    </h3>
                    <p className="services-details__document__text">3.9KB</p>
                  </div>
                  {/* /.service-widget */}
                </div>
                {/* /.service-sidebar */}
              </div>
              {/* /.column */}
              <div
                className="col-xl-8 col-lg-7 wow fadeInUp animated"
                data-wow-delay="400ms"
              >
                <div className="services-details__content">
                  <div className="services-details__thumb">
                    <img
                      src="assets/images/codeloop/services/market.png"
                      alt="ogency"
                    />
                  </div>
                  <h3 className="services-details__content__title">
                    Digital marketing
                  </h3>
                  <p className="services-details__content__text">
                    "Let us illuminate your online presence like a beacon. As
                    your digital guides, we wield cutting-edge tools to craft
                    strategies that capture attention for your brand. We're not
                    just managing social media; we're orchestrating a digital
                    evolution tailored to your success."
                  </p>
                  <h4 className="services-details__content__title-two">
                    Helping clients to solve digital marketing problems
                  </h4>
                  <p className="services-details__content__text">
                    Our expert team addresses your digital marketing challenges
                    with customized strategies, ensuring your online presence is
                    impactful and effective. We deliver high-quality, timely
                    results and provide ongoing support to optimize your
                    marketing efforts and drive growth.
                  </p>
                  <h3 className="services-details__content__title-three">
                    Why choose?
                  </h3>
                  <p className="services-details__content__text">
                    Our skilled digital marketers create tailored, innovative
                    campaigns that perfectly align with your brand’s goals,
                    ensuring satisfaction with high-quality, on-time work and
                    continuous support to keep your marketing efforts fresh and
                    effective.
                  </p>
                  <ul className="services-details__points list-unstyled">
                    <li>
                      <div className="services-details__points__icon">
                        <span className="icon-success" />
                      </div>
                      <h3 className="services-details__points__title">
                        Best creative
                        <br /> agency
                      </h3>
                    </li>
                    <li>
                      <div className="services-details__points__icon">
                        <span className="icon-job-promotion" />
                      </div>
                      <h3 className="services-details__points__title">
                        99% success
                        <br /> rate
                      </h3>
                    </li>
                    <li>
                      <div className="services-details__points__icon">
                        <span className="icon-marketing" />
                      </div>
                      <h3 className="services-details__points__title">
                        Quality
                        <br /> marketing
                      </h3>
                    </li>
                  </ul>
                  <div className="services-details__benefit">
                    <div className="row">
                      <div className="col-xl-6 col-lg-12 col-md-6">
                        <div className="services-details__benefit__img">
                        <img
                            src="./assets/images/codeloop/services/logodark.png"
                            alt="ogency"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-12 col-md-6">
                        <div className="services-details__benefit__content">
                          <h3 className="services-details__benefit__title">
                            Our benefits
                          </h3>
                          <p className="services-details__benefit__text">
                          Get the best digital marketing from the Code Loop Technology
                          </p>
                          <ul className="services-details__benefit__lists list-unstyled">
                            <li>
                              <span className="fa fa-check-circle" />
                              Cost Effective
                            </li>
                            <li>
                              <span className="fa fa-check-circle" />
                              Equal Opportunities
                            </li>
                            <li>
                              <span className="fa fa-check-circle" />
                              Creates High Brand Awareness
                            </li>
                            <li>
                              <span className="fa fa-check-circle" />
                              Higher ROI
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Services Details End */}
      </div>

      <Footer />
      <Mobilenav />
    </div>
  );
}

export default DigitalMarketing;
