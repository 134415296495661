import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Mobilenav from "../components/common/Mobilenav";
import Services from "../components/Services";

function ServicesPage() {
  return (
    <div class="page-wrapper">
      <Header />
      <div>
        <section className="page-header">
          <div className="page-header__bg" />
          {/* /.page-header__bg */}
          <div className="page-header__overlay" />
          {/* /.page-header__bg */}
          <div className="container">
            <ul className="page-header__breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>Services</span>
              </li>
            </ul>
            {/* /.page-breadcrumb list-unstyled */}
            <h2 className="page-header__title">Services</h2>
            {/* /.page-title */}
          </div>
          {/* /.container */}
        </section>
        {/* /.page-header */}
        <Services />
        <section className="why-choose">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-5 wow fadeInLeft animated"
                data-wow-delay="200ms"
              >
                <div className="section-title">
                  <h5 className="section-title__tagline section-title__tagline--has-dots">
                    why choose us
                  </h5>
                  <h2 className="section-title__title">
                    Providing the best services
                  </h2>
                </div>
                {/* section-title */}
                <div className="why-choose__box">
                  <div className="why-choose__box__icon">
                    <span className="icon-tick" />
                  </div>
                  <h3 className="why-choose__box__title">
                    We think differently
                  </h3>
                  <p className="why-choose__box__text">
                    We think differently, focusing on innovative, tailored
                    solutions to ensure optimal client satisfaction. Our
                    dedication to excellence sets us apart.
                  </p>
                </div>
                {/* Why Choose Box */}
                <div className="why-choose__box">
                  <div className="why-choose__box__icon">
                    <span className="icon-tick" />
                  </div>
                  <h3 className="why-choose__box__title">
                    High quality projects
                  </h3>
                  <p className="why-choose__box__text">
                    We consistently deliver top-tier projects using the latest
                    tools and methodologies for superior results. Every project
                    is a testament to our commitment to quality.
                  </p>
                </div>
                {/* Why Choose Box */}
                <div className="why-choose__box">
                  <div className="why-choose__box__icon">
                    <span className="icon-tick" />
                  </div>
                  <h3 className="why-choose__box__title">
                    Expert team members
                  </h3>
                  <p className="why-choose__box__text">
                    Our team of industry experts collaborates to solve complex
                    problems and achieve exceptional outcomes. Their diverse
                    skills and passion drive our success.
                  </p>
                </div>
                {/* Why Choose Box */}
              </div>
              <div className="col-lg-7">
                <div className="why-choose__image">
                  <div
                    className="why-choose__image__shape wow fadeIn animated"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/resources/why-choose-1-1.png"
                      alt="ogency"
                    />
                  </div>
                  <div
                    className="why-choose__image__author wow fadeInRight animated"
                    data-wow-delay="300ms"
                  >
                    <img
                      src="assets/images/codeloop/serve.png"
                      alt="ogency"
                    />
                  </div>
                  {/* curved-circle start*/}

                  {/* curved-circle end*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Why Choose End */}

        {/*  */}
      </div>
      <Footer />
      <Mobilenav />
    </div>
  );
}

export default ServicesPage;
