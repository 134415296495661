import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CombinePages from './pages/CombinePages';
import ServicesPage from './pages/ServicesPage';
import Webdevelopment from './pages/Webdevelopment';
import GraphicDesign from './pages/GraphicDesign';
import DigitalMarketing from './pages/DigitalMarketing';
import AppDevelopment from './pages/AppDevelopment';
import Portfolio from './pages/Portfolio';
// import Pricing from './pages/Pricing';
import Contactus from './pages/Contactus';
import Aboutus from './pages/Aboutus';
import LeadGeneration from './pages/LeadGeneration';
import EmailMarketing from './pages/EmailMarketing';
import Seo from './pages/Seo';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CombinePages />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/webdevelopment" element={<Webdevelopment />} />
        <Route path="/graphicdesigning" element={<GraphicDesign />} />
        <Route path="/digitalmarketing" element={<DigitalMarketing />} />
        <Route path="/appdevelopment" element={<AppDevelopment />} />
        <Route path="/leadgeneration" element={<LeadGeneration/>} />
        <Route path="/emailmarketing" element={<EmailMarketing/>} />
        <Route path="/seo" element={<Seo/>} />

        <Route path="/portfolio" element={<Portfolio />} />
        {/* <Route path="/pricing" element={<Pricing />} /> */}
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/aboutus" element={<Aboutus/>} />



      </Routes>
    </Router>
  );
}

export default App;
