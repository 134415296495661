import React, { useState } from 'react'
import { toast } from 'react-toastify';

function Contact() {
    const [credentials, setCredentials] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const url = 'https://apis.codeloop.tech/user/contactus';
        const options = {
          method: 'POST',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify(credentials)
        };
    
        try {
          const response = await fetch(url, options);
          if (response.ok) {
            const data = await response.json();
            toast.success("Successfully sent message to Code Loop Technology");
            console.log(data);
            setCredentials({ name: "", email: "", phone: "", message: "" });
          } else {
            toast.error("Failed to send message. Please try again.");
          }
        } catch (error) {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      };
    
      const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
      };
    
  return (
    <div>
        <section className="contact-two">
          <div
            className="container wow fadeInUp animated"
            data-wow-delay="300ms"
          >
            <div className="section-title text-center">
            <h5 className="section-title__tagline section-title__tagline--has-dots">
              <span style={{color:'#f40404'}}>Code Loop Technology</span> 
              </h5>
              <h2 className="section-title__title">
                Feel free to get in touch
                <br /> with Us
              </h2>
            </div>
            <div className="contact-one__left text-center">
              <div className="contact-one__form-box">
                <form
                  className="contact-one__form contact-form-validated"
                  // noValidate="novalidate"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-one__input-box">
                        <input
                          type="text"
                          placeholder="Your name"
                          name="name"
                          value={credentials.name}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-one__input-box">
                        <input
                          type="email"
                          placeholder="Email address"
                          name="email"
                          value={credentials.email}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="contact-one__input-box">
                        <input
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          value={credentials.phone}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="contact-one__input-box text-message-box">
                        <textarea
                          name="message"
                          placeholder="Write Comment"
                          value={credentials.message}
                          onChange={onChange}
                        />
                      </div>
                      <div className="contact-one__btn-box">
                        <button onClick={handleSubmit} className="ogency-btn">
                          Send a Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="result"></div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default Contact
