import React from "react";

function Testimonials() {
  return (
    <div>
      <section
        className="testimonial-one"
        style={{
          backgroundImage:
            "url(assets/images/backgrounds/testimonial-bg-1.png)",
        }}
      >
        <div className="container">
          <div
            className="testimonial-one__carousel ogency-owl__carousel owl-theme owl-carousel"
            data-owl-options='{
      "items": 1,
      "margin": 0,
      "smartSpeed": 700,
      "loop":true,
      "autoplay": true,
      "nav":true,
      "dots":false,
      "navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-right-arrow\"></span>"]
      }'
          >
            {/* Testimonial Item */}
            <div className="testimonial-one__item">
              <div className="testimonial-one__item__author-thumb">
                <img
                  src="assets/images/codeloop/testimonials/test1.png"
                  alt="ogency"
                />
                <span>“</span>
                {/* testimonial-quote-icon */}
              </div>
              {/* testimonial-author-thumb */}
              <div className="testimonial-one__item__content">
                <div className="testimonial-one__item__quote">
                  I was amazed by Code Loop's Social Media Marketing service!
                  They turned my digital presence into a powerhouse, attracting
                  new clients and boosting engagement. Their creativity and
                  expertise are unparalleled. Highly recommend
                </div>
                {/* testimonial-quote */}
                <div className="testimonial-one__item__meta">
                  Emily<span>.&nbsp;&nbsp;Customer</span>
                </div>
                {/* testimonial-meta */}
              </div>
              {/* testimonial-content */}
            </div>
            {/* Testimonial Item */}
            {/* Testimonial Item */}
            <div className="testimonial-one__item">
              <div className="testimonial-one__item__author-thumb">
                <img
                  src="assets/images/codeloop/testimonials/test2.png"
                  alt="ogency"
                />
                <span>“</span>
                {/* testimonial-quote-icon */}
              </div>
              {/* testimonial-author-thumb */}
              <div className="testimonial-one__item__content">
                <div className="testimonial-one__item__quote">
                  Code Loop Technology's website development service is
                  exceptional! Their team delivered a beautifully designed and
                  functional website that perfectly represents our brand. Their
                  attention to detail, professionalism, and expertise made the
                  process smooth and enjoyable. Highly recommended for anyone
                  looking for top-notch web development services!
                </div>
                {/* testimonial-quote */}
                <div className="testimonial-one__item__meta">
                  Kwame<span>.&nbsp;&nbsp;Customer</span>
                </div>
                {/* testimonial-meta */}
              </div>
              {/* testimonial-content */}
            </div>
            {/* Testimonial Item */}
            
            {/* Testimonial Item */}
            <div className="testimonial-one__item">
              <div className="testimonial-one__item__author-thumb">
                <img
                  src="assets/images/codeloop/testimonials/test5.png"
                  alt="ogency"
                />
                <span>“</span>
                {/* testimonial-quote-icon */}
              </div>
              {/* testimonial-author-thumb */}
              <div className="testimonial-one__item__content">
                <div className="testimonial-one__item__quote">
                  Code Loop's lead generation service has truly transformed our
                  business. Their strategic approach and innovative techniques
                  have consistently delivered high-quality leads, resulting in
                  increased conversions and revenue. Working with their team has
                  been a pleasure, and I highly recommend Code Loop's lead
                  generation service to anyone looking to scale their
                  business effectively.
                </div>
                {/* testimonial-quote */}
                <div className="testimonial-one__item__meta">
                  Sophia<span>.&nbsp;&nbsp;Customer</span>
                </div>
                {/* testimonial-meta */}
              </div>
              {/* testimonial-content */}
            </div>
            {/* Testimonial Item */}
            {/* Testimonial Item */}
            <div className="testimonial-one__item">
              <div className="testimonial-one__item__author-thumb">
                <img
                  src="assets/images/codeloop/testimonials/test3.png"
                  alt="ogency"
                />
                <span>“</span>
                {/* testimonial-quote-icon */}
              </div>
              {/* testimonial-author-thumb */}
              <div className="testimonial-one__item__content">
                <div className="testimonial-one__item__quote">
                  "I can't speak highly enough about the SEO service provided by
                  Code Loop. Their expertise in optimizing our website has led
                  to a significant increase in organic traffic and improved
                  search engine rankings. Thanks to their strategic approach and
                  constant monitoring, our online visibility has soared. Code
                  Loop's SEO service is a game-changer for any business looking
                  to dominate the digital landscape."
                </div>
                {/* testimonial-quote */}
                <div className="testimonial-one__item__meta">
                  Evelyn<span>.&nbsp;&nbsp;Customer</span>
                </div>
                {/* testimonial-meta */}
              </div>
              {/* testimonial-content */}
            </div>
            {/* Testimonial Item */}
          </div>
        </div>
      </section>
      {/*Testimonial End*/}
    </div>
  );
}

export default Testimonials;
