import React, { useState } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Mobilenav from "../components/common/Mobilenav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contactus() {
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "https://apis.codeloop.tech/user/contactus";
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(credentials),
    };

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const data = await response.json();
        toast.success("Successfully sent message to Code Loop Technology");
        console.log(data);
        setCredentials({ name: "", email: "", phone: "", message: "" });
      } else {
        toast.error("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div className="page-wrapper">
      <Header />
      <div>
        <section className="page-header">
          <div className="page-header__bg" />
          <div className="page-header__overlay" />
          <div className="container">
            <ul className="page-header__breadcrumb list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <span>Contact</span>
              </li>
            </ul>
            <h2 className="page-header__title">Contact Us</h2>
          </div>
        </section>
        <section className="contact-two">
          <div
            className="container wow fadeInUp animated"
            data-wow-delay="300ms"
          >
            <div className="section-title text-center">
              <h5 className="section-title__tagline section-title__tagline--has-dots">
                <span style={{ color: "#f40404" }}>Code Loop Technology</span>
              </h5>
              <h2 className="section-title__title">
                Feel free to get in touch
                <br /> with Us
              </h2>
            </div>
            <div className="contact-one__left text-center">
              <div className="contact-one__form-box">
                <form
                  className="contact-one__form contact-form-validated"
                  // noValidate="novalidate"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-one__input-box">
                        <input
                          type="text"
                          placeholder="Your name"
                          name="name"
                          value={credentials.name}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-one__input-box">
                        <input
                          type="email"
                          placeholder="Email address"
                          name="email"
                          value={credentials.email}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="contact-one__input-box">
                        <input
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          value={credentials.phone}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="contact-one__input-box text-message-box">
                        <textarea
                          name="message"
                          placeholder="Write Comment"
                          value={credentials.message}
                          onChange={onChange}
                        />
                      </div>
                      <div className="contact-one__btn-box">
                        <button onClick={handleSubmit} className="ogency-btn">
                          Send a Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="result"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-info">
          <div className="container">
            <div className="contact-info__wrapper">
              <div className="row">
                <div className="col-xl-4 col-md-6">
                  <div className="contact-info__item">
                    <div className="contact-info__item__icon">
                      <span className="icon-place" />
                    </div>
                    <h3 className="contact-info__item__title">Address</h3>
                    <p className="contact-info__item__text">
                      123 Main Street,
                      <br />
                      New York, NY 10001
                      <br />
                      USA
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="contact-info__item">
                    <div className="contact-info__item__icon">
                      <span className="icon-phone" />
                    </div>
                    <h3 className="contact-info__item__title">Contact</h3>
                    <p className="contact-info__item__text">
                      <a href="mailto:codeloop.tech@gmail.com">
                        codeloop.tech@gmail.com
                      </a>
                      {/* /  <a href="tel:+923366031601">+923366031601</a> */}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-md-7">
                  <div className="contact-info__item">
                    <div className="contact-info__item__icon">
                      <span className="icon-schedule" />
                    </div>
                    <h3 className="contact-info__item__title">Timing</h3>
                    <p className="contact-info__item__text">
                      Mon - Fri: 10:00 am to 6:00 pm
                      <br /> Sun and Sat: CLOSED
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="google-map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193109.02187907984!2d-74.259867!3d40.6976701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a1847c0b2fb%3A0x16d6a7b09f2a1dc1!2sNew%20York%2C%20NY!5e0!3m2!1sen!2sus!4v1630716582538!5m2!1sen!2sus"
            className="w-100"
            height={550}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>
      </div>
      <Footer />
      <Mobilenav />
      <ToastContainer />
    </div>
  );
}

export default Contactus;
