import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Mobilenav from "../components/common/Mobilenav";

function Portfolio() {
  return (
    <div class="page-wrapper">
      <Header />
      <div>
        <section className="page-header">
          <div className="page-header__bg" />
          {/* /.page-header__bg */}
          <div className="page-header__overlay" />
          {/* /.page-header__bg */}
          <div className="container">
            <ul className="page-header__breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>Portfolio</span>
              </li>
            </ul>
            {/* /.page-breadcrumb list-unstyled */}
            <h2 className="page-header__title">Portfolio</h2>
            {/* /.page-title */}
          </div>
          {/* /.container */}
        </section>
        {/* /.page-header */}
        {/* Projects Start */}
        <section className="project-two project-two--page">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="project-two__item">
                  <div className="project-two__item__image">
                    <img
                      src="assets/images/project/project-2-2.jpg"
                      alt="ogency"
                    />
                  </div>
                  {/* /.project-image */}
                  <div className="project-two__item__content">
                    <p className="project-two__item__content__cats">
                      <a href="projects.html">Digital</a>,{" "}
                      <a href="projects.html">Agency</a>
                    </p>
                    {/* /.project-category */}
                    <h3 className="project-two__item__content__title">
                      <a href="project-details.html">Asus marketing</a>
                    </h3>
                    {/* /.project-title */}
                  </div>
                </div>
                {/* /.project-item-two */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="project-two__item">
                  <div className="project-two__item__image">
                    <img
                      src="assets/images/project/project-2-3.jpg"
                      alt="ogency"
                    />
                  </div>
                  {/* /.project-image */}
                  <div className="project-two__item__content">
                    <p className="project-two__item__content__cats">
                      <a href="projects.html">Digital</a>,{" "}
                      <a href="projects.html">Agency</a>
                    </p>
                    {/* /.project-category */}
                    <h3 className="project-two__item__content__title">
                      <a href="project-details.html">Asus marketing</a>
                    </h3>
                    {/* /.project-title */}
                  </div>
                </div>
                {/* /.project-item-two */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="project-two__item">
                  <div className="project-two__item__image">
                    <img
                      src="assets/images/project/project-2-4.jpg"
                      alt="ogency"
                    />
                  </div>
                  {/* /.project-image */}
                  <div className="project-two__item__content">
                    <p className="project-two__item__content__cats">
                      <a href="projects.html">Digital</a>,{" "}
                      <a href="projects.html">Agency</a>
                    </p>
                    {/* /.project-category */}
                    <h3 className="project-two__item__content__title">
                      <a href="project-details.html">Asus marketing</a>
                    </h3>
                    {/* /.project-title */}
                  </div>
                </div>
                {/* /.project-item-two */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="project-two__item">
                  <div className="project-two__item__image">
                    <img
                      src="assets/images/project/project-2-5.jpg"
                      alt="ogency"
                    />
                  </div>
                  {/* /.project-image */}
                  <div className="project-two__item__content">
                    <p className="project-two__item__content__cats">
                      <a href="projects.html">Digital</a>,{" "}
                      <a href="projects.html">Agency</a>
                    </p>
                    {/* /.project-category */}
                    <h3 className="project-two__item__content__title">
                      <a href="project-details.html">Asus marketing</a>
                    </h3>
                    {/* /.project-title */}
                  </div>
                </div>
                {/* /.project-item-two */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="project-two__item">
                  <div className="project-two__item__image">
                    <img
                      src="assets/images/project/project-2-1.jpg"
                      alt="ogency"
                    />
                  </div>
                  {/* /.project-image */}
                  <div className="project-two__item__content">
                    <p className="project-two__item__content__cats">
                      <a href="projects.html">Digital</a>,{" "}
                      <a href="projects.html">Agency</a>
                    </p>
                    {/* /.project-category */}
                    <h3 className="project-two__item__content__title">
                      <a href="project-details.html">Asus marketing</a>
                    </h3>
                    {/* /.project-title */}
                  </div>
                </div>
                {/* /.project-item-two */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="project-two__item">
                  <div className="project-two__item__image">
                    <img
                      src="assets/images/project/project-2-6.jpg"
                      alt="ogency"
                    />
                  </div>
                  {/* /.project-image */}
                  <div className="project-two__item__content">
                    <p className="project-two__item__content__cats">
                      <a href="projects.html">Digital</a>,{" "}
                      <a href="projects.html">Agency</a>
                    </p>
                    {/* /.project-category */}
                    <h3 className="project-two__item__content__title">
                      <a href="project-details.html">Asus marketing</a>
                    </h3>
                    {/* /.project-title */}
                  </div>
                </div>
                {/* /.project-item-two */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <Mobilenav />
    </div>
  );
}

export default Portfolio;
