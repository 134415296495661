import React, { useState, useEffect, lazy, Suspense } from "react";
import Loader from "../components/common/Loader";
import Mobilenav from "../components/common/Mobilenav";
import { ToastContainer } from "react-toastify";
import Contact from "../components/Contact";

const Header = lazy(() => import("../components/common/Header"));
const MainSlider = lazy(() => import("../components/MainSlider"));
const Feature = lazy(() => import("../components/Feature"));
const About = lazy(() => import("../components/About"));
const Slidingtext = lazy(() => import("../components/Slidingtext"));
// const Video = lazy(() => import("../components/Video"));
const Services = lazy(() => import("../components/Services"));
const Projectstart = lazy(() => import("../components/Projectstart"));
const Fact = lazy(() => import("../components/Fact"));
const Slidingtextt = lazy(() => import("../components/Slidingtextt"));
const Testimonials = lazy(() => import("../components/Testimonials"));
const Calltoaction = lazy(() => import("../components/Calltoaction"));
const Footer = lazy(() => import("../components/common/Footer"));

function CombinePages() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a 1-second delay before hiding the loader
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, []);

  return (
    <div>
      {isLoading && <Loader isLoading={isLoading} />}

      <div className="page-wrapper">
        <Suspense fallback={<Loader isLoading={true} />}>
          <Header />
          <MainSlider />
          {/* <ClientCarousel /> */}
          <Feature />
          <About />
          <Slidingtext />
          {/* <Video /> */}
          <Services />
          <Projectstart />
          <Fact />
          <Slidingtextt />
          <Testimonials />
          <Contact />
          <Calltoaction />
          <Footer />
        </Suspense>
        <ToastContainer />
      </div>
      <Mobilenav />
    </div>
  );
}

export default CombinePages;
