import React from 'react';

function Loader({ isLoading }) {
  if (isLoading) {
    return (
      <div className="preloader">
        <div className="preloader__image"
             style={{
               backgroundImage: 'url(./assets/images/logo-lightt.png)',
               backgroundSize: '150px 150px', // Adjust width and height as needed
               backgroundRepeat: 'no-repeat',
               backgroundPosition: 'center',
             }}
        >
        </div>
      </div>
    );
  }
  return null; // Return null when not loading
}

export default Loader;
