import React from 'react'

function Services() {
  return (
    <div>
          {/* Service Start */}
    <section className="service-one @@extraClassName">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title text-center">
              <h5 className="section-title__tagline section-title__tagline--has-dots">what we’re offering</h5>
              <h2 className="section-title__title">Services we’re providing<br /> to our customers</h2>
            </div>{/* section-title */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 wow fadeInUp animated" data-wow-delay="100ms">
            <div className="service-one__item">
              <div className="service-one__item__icon">
              <img src="./assets/images/web.png" alt="" />
              </div>{/* /.service-icon */}
              <h3 className="service-one__item__title">
                <a href="/webdevelopment">Website development</a>
              </h3>{/* /.service-title */}
              <p className="service-one__item__text">Expert web development to elevate your online presence</p>{/* /.service-content */}
              <a className="service-one__item__btn" href="/webdevelopment">Read More<span className="icon-down-right" /></a>{/* /.service-read-more */}
            </div>{/* /.service-card-one */}
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp animated" data-wow-delay="200ms">
            <div className="service-one__item">
              <div className="service-one__item__icon">
              <img src="./assets/images/graphic.png" alt="" />

              </div>{/* /.service-icon */}
              <h3 className="service-one__item__title">
                <a href="/graphicdesigning">Graphic designing</a>
              </h3>{/* /.service-title */}
              <p className="service-one__item__text">Creative graphic design to make your brand stand out</p>{/* /.service-content */}
              <a className="service-one__item__btn" href="/graphicdesigning">Read More<span className="icon-down-right" /></a>{/* /.service-read-more */}
            </div>{/* /.service-card-one */}
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp animated" data-wow-delay="100ms">
            <div className="service-one__item">
              <div className="service-one__item__icon">
              <img src="./assets/images/digital.png" alt="" />

              </div>{/* /.service-icon */}
              <h3 className="service-one__item__title">
                <a href="/digitalmarketing">Digital marketing</a>
              </h3>{/* /.service-title */}
              <p className="service-one__item__text">Effective digital marketing to boost your online growth</p>{/* /.service-content */}
              <a className="service-one__item__btn" href="/digitalmarketing">Read More<span className="icon-down-right" /></a>{/* /.service-read-more */}
            </div>{/* /.service-card-one */}
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp animated" data-wow-delay="100ms">

            <div className="service-one__item">
              <div className="service-one__item__icon">
                <img src="./assets/images/app.png" alt="" />
              </div>{/* /.service-icon */}
              <h3 className="service-one__item__title">
                <a href="/appdevelopment">Apps development</a>
              </h3>{/* /.service-title */}
              <p className="service-one__item__text">Custom app development to bring your ideas to life</p>{/* /.service-content */}
              <a className="service-one__item__btn" href="/appdevelopment">Read More<span className="icon-down-right" /></a>{/* /.service-read-more */}
            </div>{/* /.service-card-one */}
          </div>
   
          <div className="col-lg-3 col-md-6 wow fadeInUp animated mt-5" data-wow-delay="100ms">
            <div className="service-one__item">
              <div className="service-one__item__icon">
                <img src="./assets/images/user.png" alt="" />
              </div>{/* /.service-icon */}
              <h3 className="service-one__item__title">
                <a href="/leadgeneration"> <span style={{fontSize:17}}>Lead Generation</span></a>
              </h3>{/* /.service-title */}
              <p className="service-one__item__text">Targeted lead generation to grow your customer base</p>{/* /.service-content */}
              <a className="service-one__item__btn" href="/leadgeneration">Read More<span className="icon-down-right" /></a>{/* /.service-read-more */}
            </div>{/* /.service-card-one */}
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp animated mt-5" data-wow-delay="100ms">
            <div className="service-one__item">
              <div className="service-one__item__icon">
                <img src="./assets/images/seo.png" alt="" />
              </div>{/* /.service-icon */}
              <h3 className="service-one__item__title">
                <a href="/seo">SEO </a>
              </h3>{/* /.service-title */}
              <p className="service-one__item__text">Expert SEO services to enhance your search rankings</p>{/* /.service-content */}
              <a className="service-one__item__btn" href="/seo">Read More<span className="icon-down-right" /></a>{/* /.service-read-more */}
            </div>{/* /.service-card-one */}
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp animated mt-5" data-wow-delay="100ms">
            <div className="service-one__item">
              <div className="service-one__item__icon">
               <img src="./assets/images/mail.png" alt="" />
              </div>{/* /.service-icon */}
              <h3 className="service-one__item__title">
                <a href="/emailmarketing">Email Marketing</a>
              </h3>{/* /.service-title */}
              <p className="service-one__item__text">Effective email marketing to engage and convert customers</p>{/* /.service-content */}
              <a className="service-one__item__btn" href="/emailmarketing">Read More<span className="icon-down-right" /></a>{/* /.service-read-more */}
            </div>{/* /.service-card-one */}
          </div>
        
        </div>
      </div>
    </section>
    {/* Service Start */}
    </div>
  )
}

export default Services
